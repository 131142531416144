import { cookieCountries } from 'app/modules/Constants';
import classNames from 'classnames';
import Image from 'next/image';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { agreeCookieNotice } from '../../../actions/baseActions';
import Helper from '../../modules/Helper';
import Trans from './Trans';

type CookieNoticeProps = {
	countryCode: string;
	cookieNotice: boolean;
	lng: string;
	agreeCookieNotice: () => void;
};

const CookieNotice: FC<CookieNoticeProps> = (props) => {
	const [renderCookieConsent, setRenderCookieConsent] = useState(false);
	const { cookieNotice, agreeCookieNotice, countryCode, lng } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (countryCode && !cookieCountries.includes(countryCode)) {
			setRenderCookieConsent(true);
		}
	}, [countryCode]);

	if (cookieNotice || !renderCookieConsent) {
		return null;
	}
	const isFromEU = Helper.isFromEU(countryCode);
	const isJapanese = lng === 'jp';
	const closeIconDimension = Helper.isMobile() ? 16 : 24;
	return (
		<div id="cookie-notice">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-md-11 col-10">
						<p
							className={classNames('description d-lg-block d-none', { japanese: isJapanese })}
							dangerouslySetInnerHTML={{
								__html: t('cookieNoticeDesktop'),
							}}
						/>
						<p
							className="description d-lg-none d-block"
							dangerouslySetInnerHTML={{
								__html: t(isFromEU ? 'cookieNoticeMobileEU' : 'cookieNoticeMobile'),
							}}
						/>
					</div>
					<div onClick={agreeCookieNotice} className="col-md-1 col-2">
						{isFromEU ? (
							<button type="button" className="btn btn-primary btn-blue cookie-confirm-btn">
								<Trans i18nKey="keywords.ok" />
							</button>
						) : (
							<Image
								width={isJapanese ? 14 : closeIconDimension}
								height={isJapanese ? 14 : closeIconDimension}
								src="/images/close.png"
								alt="Close"
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	cookieNotice: state.base.cookieNotice,
	countryCode: state.base.countryCode,
	lng: state.base.lng,
});

export default connect(mapStateToProps, { agreeCookieNotice })(React.memo(CookieNotice));
